
















































import Vue from 'vue';
import { Store, mapState } from 'vuex';
import { StateRoot } from '../types/store';

export default Vue.extend({

  computed: {


    ...mapState('meeting', [
      'requestList',
      'requestRecognized'
    ]),
    ...mapState('buttons', [
      'yesText',
      'noText',
      'abstainText'
    ]),

    voteTotals() {
      const store = this.$store as Store<StateRoot>;
      return store.state.voteTotals;
    }

/*
    // TODO: consider strongly typed store: https://stackoverflow.com/a/60535432/702931
    store(): Store<StateRoot> {
      return this.$store as Store<StateRoot>;
    },
*/
  }

});
