import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import './assets/site.css';

import App from './App.vue';
import './registerServiceWorker';
import store from './store';


Vue.config.productionTip = false; // !!!! process.env.NODE_ENV !== 'production';
Vue.config.devtools = true; // !!!! process.env.NODE_ENV !== 'production';

library.add(faSpinner, faCheck);

Vue.component('fa-icon', FontAwesomeIcon);

Vue.use(BootstrapVue);

new Vue({
  store,
  render: h => h(App)
}).$mount('#app');
