






import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({

  data() {
    return {
      tickInterval: 0,
      time: ''
    };
  },

  computed: {

    ...mapGetters('panel', [
      'authenticated'
    ])

  },

  methods: {

    ...mapActions('panel', [
      'logout'
    ])

  }

});
