import { Module } from 'vuex';
import { StateRoot, VoteTotalsState } from '../types/store';

const initState: VoteTotalsState = {
  yes: 0,
  no: 0,
  abstain: 0,
  recused: 0,
  absent: 0
};

const voteTotalsStore: Module<VoteTotalsState, StateRoot> = {
  namespaced: true as const,
  state: initState,
  getters: {},
  actions: {
  },
  mutations: {
    clearVote(state: VoteTotalsState): void {
      state.yes = 0;
      state.no = 0;
      state.abstain = 0;
      state.recused = 0;
      state.absent = 0;
    },
    setVote(state: VoteTotalsState, args: VoteTotalsState): void {
      Object.assign(state, args);
    }
  }
};

export default voteTotalsStore;
