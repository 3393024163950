
export default function parseCanVote(votingSeatStatus: number): boolean {
  let canVote = false;
  switch (votingSeatStatus) {
    case 35:
    case 38:
    case 39:
      canVote = true;
      break;
    case 36:
    default:
      canVote = false;
      break;
  }
  return canVote;
}
