














import Vue from 'vue';
import { mapState } from 'vuex';
import Clock from './Clock.vue';
import Logout from './Logout.vue';

export default Vue.extend({

  components: {
    Clock,
    Logout
  },

  computed: {

    ...mapState('panel', [
      'timer',
      'timerLight',
      'panelId',
      'panelName'
    ])

  }

});
