

































import Vue from 'vue';
import { mapState, mapActions } from 'vuex';

export default Vue.extend({

  data() {
    return {
      // local state:
      authFail: false,
      storeSubscribe: () => {/*no-op until store fills it*/}
    };
  },

  props: {
    vertical: {
      type: Boolean,
      default: false
    }
  },

  computed: {

    motionFirstText() {
      return this.motionFirstLight ? 'MOTION MADE' : 'MAKE MOTION';
    },
    motionSecondText() {
      return this.motionSecondLight ? 'SECOND MADE' : 'SECOND MOTION';
    },

    ...mapState('panel', [
      'canVote'
    ]),

    ...mapState('meeting', [
      'voteState'
    ]),

    ...mapState('buttons', [
      'voteYesEnabled',
      'voteYesLit',
      'hideVote',
      'voteNoLit',
      'voteNoEnabled',
      'voteAbstainEnabled',
      'voteAbstainLit',
      'yesText',
      'noText',
      'abstainText',
      'motionFirstEnabled',
      'motionFirstLight',
      'motionSecondEnabled',
      'motionSecondLight',
      'micColor',
      'micText',
      'micStatus'
    ])

  },

  methods: {

    ...mapActions('buttons', [
      'voteYes',
      'voteNo',
      'voteAbstain',
      'makeMotionFirst',
      'makeMotionSecond',
      'pushMicButton'
    ])

  }

});
