import { ActionContext, Module } from 'vuex';
import { StateRoot, PanelState, ContentType } from '../types/store';
import { LoginDone, NameOffice } from '../types/panel';

const TOKEN_COOKIE = 'token';

const initState: PanelState = {
  contentVisible: 'browser',
  timer: '0:00',
  timerLight: 'grey',
  panelId: 0,
  panelName: '',
  panelOffice: '',
  canVote: true,

  // Internal state
  tcpConnected: false,
  username: undefined,
  authToken: undefined
};

const panelStore: Module<PanelState, StateRoot> = {
  namespaced: true as const,
  state: initState,
  getters: {
    authenticated: state => !!state.authToken,
    connected: (state: PanelState, getters: unknown, rootState: StateRoot/*, rootGetters*/): boolean => {
      return state.tcpConnected && rootState.message.socketConnected && !rootState.message.socketFail;
    }
  },
  actions: {

    loginRestore(context: ActionContext<PanelState, StateRoot>): void {
      const authToken = sessionStorage[TOKEN_COOKIE] as string | undefined;
      context.commit('authToken', authToken);
      if (authToken) {
        // GET_INIT will check if it's valid and log us out if it isn't
        context.dispatch('message/sendMessage', `*GET_INIT|${authToken}!`, {root: true});
      }
    },

    loginStart(context: ActionContext<PanelState, StateRoot>, password: string): void {
      // 6 random digits
      const username = '' + Math.floor(Math.random() * 1000000);
      context.commit('username', username);
      context.dispatch('message/sendMessage', `*LOGIN|${username}|${password}!`, {root: true});
    },

    loginDone(context: ActionContext<PanelState, StateRoot>, args: LoginDone): void {
      if (!args.authenticated) {
        args.authToken = undefined;
      }
      if (!args.panelId || isNaN(args.panelId)) {
        args.panelId = 0;
      }
      if (args.authenticated && args.username !== context.state.username) {
        context.dispatch('message/otherUserMessage', `Login message for different user: ${JSON.stringify(args)}`, {root: true});
        return;
      }
      context.commit('loginDone', args);
    },

    logout(context: ActionContext<PanelState, StateRoot>): void {
      const authToken = context.state.authToken;
      const loginDone: LoginDone = {
        authenticated: false
      };
      context.dispatch('loginDone', loginDone);
      if (authToken) {
        context.dispatch('message/sendMessage', `*LOGOFF|${authToken}!`, {root: true});
      }
    }

  },
  mutations: {

    contentVisible(state: PanelState, contentVisible: ContentType): void {
      state.contentVisible = contentVisible;
    },

    tcpConnected(state: PanelState, tcpConnected: boolean): void {
      state.tcpConnected = tcpConnected;
    },
    authToken(state: PanelState, authToken: string): void {
      state.authToken = authToken;
      state.username = undefined;
    },
    username(state: PanelState, username: string): void {
      state.username = username;
    },
    timer(state: PanelState, timer: string): void {
      state.timer = timer;
    },
    timerLight(state: PanelState, timerLight: string): void {
      state.timerLight = timerLight;
    },


    loginDone(state: PanelState, args: LoginDone): void {
      if (args.authToken) {
        sessionStorage[TOKEN_COOKIE] = args.authToken;
      } else {
        delete sessionStorage[TOKEN_COOKIE];
      }
      state.username = args.username;
      state.authToken = args.authToken;
      state.panelId = args.panelId ?? 0;
      state.canVote = args.canVote ?? false;
      state.panelName = args.panelName ?? '';
      state.panelOffice = args.panelOffice ?? '';
      state.username = undefined; // don't need it anymore
    },

    nameOffice(state: PanelState, args: NameOffice): void {
      if (isNaN(args.panelId)) {
        args.panelId = 0;
      }
      Object.assign(state, args);
    }

  }
};

export default panelStore;
