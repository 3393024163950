import { ActionContext, Module } from 'vuex';
import { StateRoot, MeetingState } from '../types/store';
import { AgendaItemCurrent, Speaker, SpeakerChange, RequestListItem } from '@/types/meeting';



const initState: MeetingState = {
  iframeUrl: undefined,
  videoUrl: undefined,
  agendaCurrentNumber: '',
  agendaCurrentText: '',
  requestList: [],
  requestRecognized: '',
  speakers: [],
  currentSpeaker: '',
  motionFirstBy: '',
  motionSecondBy: '',
  voteState: 'Vote Idle',
};

const meetingStore: Module<MeetingState, StateRoot> = {
  namespaced: true as const,
  state: initState,
  getters: {},
  actions: {

    addSpeakerToList(context: ActionContext<MeetingState, StateRoot>, args: Speaker): void {
      context.commit('addSpeakerToList', args);
      context.commit('setActiveSpeaker');
    },

    clearSpeakerList(context: ActionContext<MeetingState, StateRoot>): void {
      context.commit('clearSpeakerList');
      context.commit('setActiveSpeaker');
    },

    changeSpeakerState(context: ActionContext<MeetingState, StateRoot>, args: SpeakerChange): void {
      context.commit('changeSpeakerState', args);
    },

    clearVote(context: ActionContext<MeetingState, StateRoot>): void {
      context.commit('buttons/clearVote', undefined, {root: true});
      context.commit('meeting/clearVote', undefined, {root: true});
      context.commit('voteTotals/clearVote', undefined, {root: true});
    },

    resetVote(context: ActionContext<MeetingState, StateRoot>): void {
      context.commit('meeting/resetVote', undefined, {root: true});
      context.commit('buttons/resetVote', undefined, {root: true});
      context.commit('voteTotals/clearVote', undefined, {root: true});
    },

    resetVotePanel(context: ActionContext<MeetingState, StateRoot>): void {
      context.commit('meeting/resetVote', undefined, {root: true});
      context.commit('buttons/resetVotePanel', undefined, {root: true});
      context.commit('voteTotals/clearVote', undefined, {root: true});
    },

    startVote(context: ActionContext<MeetingState, StateRoot>): void {
      context.commit('meeting/startVote', undefined, {root: true});
      context.commit('buttons/startVote', undefined, {root: true});
    },

    recordVote(context: ActionContext<MeetingState, StateRoot>): void {
      context.commit('meeting/recordVote', undefined, {root: true});
      context.commit('buttons/recordVote', undefined, {root: true});
    },

    cancelVote(context: ActionContext<MeetingState, StateRoot>): void {
      context.commit('meeting/cancelVote', undefined, {root: true});
      context.commit('buttons/cancelVote', undefined, {root: true});
      context.commit('voteTotals/clearVote', undefined, {root: true});
    }

  },
  mutations: {

    voteState(state: MeetingState, voteState: string): void {
      state.voteState = voteState;
    },

    agendaItem(state: MeetingState, args: AgendaItemCurrent): void {
      Object.assign(state, args);
    },

    setActiveSpeaker(state: MeetingState): void {
      const spkr = state.speakers.find(s => s.speakerState === 1);
      state.currentSpeaker = spkr?.name || '';
    },

    addSpeakerToList(state: MeetingState, args: Speaker): void {
      state.speakers.push(args);
    },

    clearSpeakerList(state: MeetingState): void {
      state.speakers = [];
    },

    changeSpeakerState(state: MeetingState, args: SpeakerChange): void {
      const spkr = state.speakers.find(s => s.id === args.id);
      if (spkr) {
        spkr.speakerState = args.speakerState;
      } else {
        console.error(`can't find speaker to change ${args.id}`);
      }
    },


    requestListAdd(state: MeetingState, args: RequestListItem): void {
      state.requestList.push(args);
    },

    requestListRemove(state: MeetingState, id: string): void {
      state.requestList = state.requestList.filter(s => s.id !== id);
    },

    requestListClear(state: MeetingState): void {
      state.requestList = [];
    },

    requestListRecognize(state: MeetingState, id: string): void {
      const spkr = state.requestList.find(s => s.id === id);
      if (spkr) {
        state.requestRecognized = spkr.name;
      } else {
        state.requestRecognized = 'unknown';
      }
    },

    requestListUnrecognize(state: MeetingState): void {
      state.requestRecognized = '';
    },


    clearVote(state: MeetingState): void {
      state.voteState = 'System Idle';
      state.motionFirstBy = '';
      state.motionSecondBy = '';
    },

    resetVote(state: MeetingState): void {
      state.voteState = 'Make Motions';
      state.motionFirstBy = '';
      state.motionSecondBy = '';
    },

    motionFirstBy(state: MeetingState, motionFirstBy: string): void {
      state.motionFirstBy = motionFirstBy;
    },

    motionSecondBy(state: MeetingState, motionSecondBy: string): void {
      state.motionSecondBy = motionSecondBy;
    },

    firstMotionByMe(state: MeetingState): void {
      state.voteState = 'Motion Made';
    },

    firstMotionByOtherUser(state: MeetingState): void {
      state.voteState = 'Second Motion';
    },

    firstMotionOff(state: MeetingState): void {
      state.voteState = 'Make Motions';
    },

    secondMotionByMe(state: MeetingState): void {
      state.voteState = 'Second Motion Made';
    },

    secondMotionByOtherUser(state: MeetingState): void {
      state.voteState = 'Motions Locked';
    },

    secondMotionOff(state: MeetingState): void {
      state.voteState = 'Motion Made';
    },

    startVote(state: MeetingState): void {
      state.voteState = 'Cast Vote';
    },

    recordVote(state: MeetingState): void {
      state.voteState = 'Vote Recorded';
    },

    cancelVote(state: MeetingState): void {
      state.voteState = 'Vote Idle';
    },


    iframeUrl(state: MeetingState, iframeUrl: string | undefined): void {
      if (iframeUrl && iframeUrl.indexOf('://') < 0) {
        iframeUrl = 'http://' + iframeUrl;
      }
      state.iframeUrl = iframeUrl;
    },

    videoUrl(state: MeetingState, videoUrl: string | undefined): void {
      state.videoUrl = videoUrl;
    }

  }
};

export default meetingStore;

/*

    */
