




import Vue from 'vue';

export default Vue.extend({

  data() {
    return {
      tickInterval: 0,
      time: ''
    };
  },

  mounted() {
    this.tickInterval = window.setInterval(this.tick.bind(this), 1000);
    this.tick();
  },

  beforeDestroy() {
    if (this.tickInterval) {
      clearInterval(this.tickInterval);
      this.tickInterval = 0;
    }
  },

  methods: {

    tick() {
      // we don't get clock messages from the server

      const time = new Date();
      const hour = time.getHours();
      const minute = time.getMinutes();
      let temp = '' + ((hour > 12) ? hour - 12 : hour);
      if (hour === 0) {
        temp = '12';
      }
      temp += `:${minute < 10 ? '0' + minute : minute} ${(hour >= 12) ? 'pm' : 'am'}`;

      this.time = temp;
    }

  }

});
