

























import Vue from 'vue';
import { mapState } from 'vuex';
import { ContentType } from '@/types/store';

export default Vue.extend({

  props: {
    horizontal: {
      type: Boolean,
      default: false
    }
  },

  computed: {

    ...mapState('panel', [
      'contentVisible'
    ]),

  },

  methods: {

    changeContent(contentVisible: ContentType) {
      this.$store.commit('panel/contentVisible', contentVisible);
    }

  }

});
