















import Vue from 'vue';
import { mapState } from 'vuex';
import Buttons from './Buttons.vue';

export default Vue.extend({

  components: {
    Buttons
  },

  props: {
    vertical: {
      type: Boolean,
      default: false
    }
  },

  computed: {

    ...mapState('meeting', [
      'voteState'
    ]),

    ...mapState('meeting', [
      'motionFirstBy',
      'motionSecondBy'
    ])

  }

});
