
























import Vue from 'vue';
import { Store, mapState } from 'vuex';
import { StateRoot } from '../types/store';

export default Vue.extend({

  data() {
    return {
      // local state:
      password: '',
      loginRunning: false,
      authFail: false,
      storeUnsubscriber: () => {/*no-op until store fills it*/}
    };
  },

  computed: {

    inputPlaceholder() {
      if (this.tcpConnected) {
        return 'Pin Code';
      } else {
        return 'Listening for Controller ...';
      }
    },

    ...mapState('panel', [
      'tcpConnected'
    ])

  },

  mounted() {
    this.storeSubscribe('panel/loginDone', this.loginDone.bind(this));
  },

  beforeDestroy() {
    this.storeUnsubscriber();
    this.storeUnsubscriber = () => {/*no-op*/};
  },

  methods: {

    loginSubmit() {
      this.loginRunning = true;
      this.authFail = false;
      const store = this.$store as Store<StateRoot>;
      store.dispatch('panel/loginStart', this.password);
    },

    // FRAGILE: this waits indefinitely for server to reply
    // If server doesn't reply, we spin forever

    loginDone() {
      this.loginRunning = false;
      this.password = '';
      const store = this.$store as Store<StateRoot>;
      this.authFail = !store.state.panel.authToken;
      const pass = this.$refs.password as HTMLFormElement;
      pass.focus();
    },

    // wish I could subscribe to specific messages instead
    storeSubscribe(actionType: string, done: () => void) {
      const store = this.$store as Store<StateRoot>;
      this.storeUnsubscriber = store.subscribe(function cb(action: {type: string}/*, state: StateRoot*/) {
        if (action.type === actionType) {
          done();
        }
      }.bind(this));
    },

  }

});
