











import Vue from 'vue';
import { Store } from 'vuex';
import { StateRoot } from '../types/store';
import Logout from './Logout.vue';

export default Vue.extend({

  components: {
    Logout
  },

  computed: {

    micStatus() {
      const store = this.$store as Store<StateRoot>;
      return store.state.buttons.micStatus;
    },
    tcpConnected() {
      const store = this.$store as Store<StateRoot>;
      return store.state.panel.tcpConnected;
    }

  }

});
