import { ActionContext, Module } from 'vuex';
import { StateRoot, ButtonsState, Vote } from '../types/store';
import { GusInit } from '@/types/buttons';

const initState: ButtonsState = {
  myVote: undefined,
  voteYesEnabled: false,
  voteYesLit: false,
  hideVote: false, // as soon as I vote, light up all 3 vote buttons so others can't tell
  lockMotion: false,
  changeVote: false,
  voteNoLit: false,
  voteNoEnabled: false,
  voteAbstainEnabled: false,
  voteAbstainLit: false,
  yesText: 'YES',
  noText: 'NO',
  abstainText: 'ABSTAIN',
  motionFirstEnabled: false,
  motionFirstLight: false,
  motionSecondEnabled: false,
  motionSecondLight: false,
  micColor: 'off',
  micText: 'MIC IS OFF',
  micStatus: '',
  micRtsMode: 'DISCUSS_OFF',
};

function getAuthToken(context: ActionContext<ButtonsState, StateRoot>): string | undefined {
  return context.rootState.panel.authToken;
}

const buttonsStore: Module<ButtonsState, StateRoot> = {
  namespaced: true as const,
  state: initState,
  getters: {},
  actions: {

    // GUI button pushes:

    voteYes(context: ActionContext<ButtonsState, StateRoot>): void {
      context.commit('castVote', 'YES');
      context.commit('meeting/voteState', 'Voted', {root: true});
      context.dispatch('message/sendMessage', `*BP1|${getAuthToken(context)}!`, {root: true});
    },

    voteNo(context: ActionContext<ButtonsState, StateRoot>): void {
      context.commit('castVote', 'NO');
      context.commit('meeting/voteState', 'Voted', {root: true});
      context.dispatch('message/sendMessage', `*BP2|${getAuthToken(context)}!`, {root: true});
    },

    voteAbstain(context: ActionContext<ButtonsState, StateRoot>): void {
      context.commit('castVote', 'ABSTAIN');
      context.commit('meeting/voteState', 'Voted', {root: true});
      context.dispatch('message/sendMessage', `*BP3|${getAuthToken(context)}!`, {root: true});
    },

    makeMotionFirst(context: ActionContext<ButtonsState, StateRoot>): void {
      context.dispatch('message/sendMessage', `*BP4|${getAuthToken(context)}!`, {root: true});
    },

    makeMotionSecond(context: ActionContext<ButtonsState, StateRoot>): void {
      context.dispatch('message/sendMessage', `*BP5|${getAuthToken(context)}!`, {root: true});
    },

    pushMicButton(context: ActionContext<ButtonsState, StateRoot>): void {
      context.dispatch('message/sendMessage', `*BP6|${getAuthToken(context)}!`, {root: true});
    },

    // Inbound messages:

    firstMotionByMe(context: ActionContext<ButtonsState, StateRoot>): void {
      context.commit('firstMotionByMe');
      context.commit('meeting/firstMotionByMe', undefined, {root: true});
    },

    firstMotionByOtherUser(context: ActionContext<ButtonsState, StateRoot>): void {
      context.commit('firstMotionByOtherUser');
      context.commit('meeting/firstMotionByOtherUser', undefined, {root: true});
    },

    firstMotionOff(context: ActionContext<ButtonsState, StateRoot>): void {
      context.commit('firstMotionOff');
      context.commit('meeting/firstMotionOff', undefined, {root: true});
    },

    secondMotionByMe(context: ActionContext<ButtonsState, StateRoot>): void {
      context.commit('secondMotionByMe');
      context.commit('meeting/secondMotionByMe', undefined, {root: true});
    },

    secondMotionByOtherUser(context: ActionContext<ButtonsState, StateRoot>): void {
      context.commit('secondMotionByOtherUser');
      context.commit('meeting/secondMotionByOtherUser', undefined, {root: true});
    },

    secondMotionOff(context: ActionContext<ButtonsState, StateRoot>): void {
      context.commit('secondMotionOff');
      context.commit('meeting/secondMotionOff', undefined, {root: true});
    }

  },
  mutations: {

    gusInit(state: ButtonsState, args: GusInit): void {
      Object.assign(state, args);
    },

    clearVote(state: ButtonsState): void {
      state.myVote = undefined;

      state.voteYesEnabled = false;
      state.voteNoEnabled = false;
      state.voteAbstainEnabled = false;
      state.motionFirstEnabled = false;
      state.motionSecondEnabled = false;

      state.voteYesLit = false;
      state.voteNoLit = false;
      state.voteAbstainLit = false;
      state.motionFirstLight = false;
      state.motionSecondLight = false;
    },

    resetVote(state: ButtonsState): void {
      // resetVote except leave the motion buttons alone
      state.myVote = undefined;

      state.voteYesEnabled = false;
      state.voteNoEnabled = false;
      state.voteAbstainEnabled = false;

      state.voteYesLit = false;
      state.voteNoLit = false;
      state.voteAbstainLit = false;
    },

    resetVotePanel(state: ButtonsState): void {
      state.myVote = undefined;

      state.voteYesEnabled = false;
      state.voteNoEnabled = false;
      state.voteAbstainEnabled = false;
      state.motionFirstEnabled = true; // <- clear vote except
      state.motionSecondEnabled = false;

      state.voteYesLit = false;
      state.voteNoLit = false;
      state.voteAbstainLit = false;
      state.motionFirstLight = false;
      state.motionSecondLight = false;
    },

    motionFirstByOtherUser(state: ButtonsState): void {
      // motion is made by someone else, let's setup seconding the motion
      state.voteYesEnabled = false;
      state.voteNoEnabled = false;
      state.voteAbstainEnabled = false;
      state.motionFirstEnabled = false;
      state.motionSecondEnabled = true; // <- clear vote except state.

      state.voteYesLit = false;
      state.voteNoLit = false;
      state.voteAbstainLit = false;
      state.motionFirstLight = false;
      state.motionSecondLight = false;
    },

    motionSecondByOtherUser(state: ButtonsState): void {
      // user made a second motion but voting hasn't started
      state.motionSecondEnabled = false;
      state.motionFirstEnabled = false;
      state.motionFirstLight = false;
      state.motionSecondLight = false;
    },

    startVote(state: ButtonsState): void {
      state.myVote = undefined;

      state.voteYesEnabled = true;
      state.voteNoEnabled = true;
      state.voteAbstainEnabled = true;

      state.voteYesLit = true;
      state.voteNoLit = true;
      state.voteAbstainLit = true;
      // doesn't change motionFirstLight and motionSecondLight
    },

    castVote(state: ButtonsState, myVote: Vote): void {
      state.myVote = myVote;

      if (!state.changeVote) {
        state.voteYesEnabled = false;
        state.voteNoEnabled = false;
        state.voteAbstainEnabled = false;
      }
      if (!state.hideVote) {
        state.voteNoLit = false;
        state.voteAbstainLit = false;
      }
    },

    yesButtonByMe(state: ButtonsState): void {
      if (state.hideVote) {
        state.voteYesLit = true;
        state.voteNoLit = true;
        state.voteAbstainLit = true;
      } else {
        state.voteYesLit = true;
        state.voteNoLit = false;
        state.voteAbstainLit = false;
      }
    },

    noButtonByMe(state: ButtonsState): void {
      if (state.hideVote) {
        state.voteYesLit = true;
        state.voteNoLit = true;
        state.voteAbstainLit = true;
      } else {
        state.voteYesLit = false;
        state.voteNoLit = true;
        state.voteAbstainLit = false;
      }
    },

    abstainButtonByMe(state: ButtonsState): void {
      if (state.hideVote) {
        state.voteYesLit = true;
        state.voteNoLit = true;
        state.voteAbstainLit = true;
      } else {
        state.voteYesLit = false;
        state.voteNoLit = false;
        state.voteAbstainLit = true;
      }
    },

    firstMotionByMe(state: ButtonsState): void {
      state.motionSecondEnabled = false;
      state.motionFirstEnabled = true;
      state.motionFirstLight = true;
    },

    firstMotionByOtherUser(state: ButtonsState): void {
      state.motionSecondEnabled = true;
      state.motionFirstEnabled = false;
    },

    firstMotionOff(state: ButtonsState): void {
      state.motionSecondEnabled = false;
      state.motionFirstEnabled = true;
      state.motionFirstLight = false;
    },

    secondMotionByMe(state: ButtonsState): void {
      state.motionFirstLight = false;
      state.motionSecondEnabled = true;
      state.motionSecondLight = true;
    },

    secondMotionByOtherUser(state: ButtonsState): void {
      state.motionSecondEnabled = false;
    },

    secondMotionOff(state: ButtonsState): void {
      // TODO: why is this dependent on the first motion light?
      state.motionSecondEnabled = !state.motionFirstLight;
      state.motionSecondLight = false;
    },




    recordVote(state: ButtonsState): void {
      state.voteYesEnabled = false;
      state.voteNoEnabled = false;
      state.voteAbstainEnabled = false;

      state.voteYesLit = false;
      state.voteNoLit = false;
      state.voteAbstainLit = false;

      switch (state.myVote) {
        case 'YES':
          state.voteYesEnabled = true;
          state.voteYesLit = true;
          break;
        case 'NO':
          state.voteNoEnabled = true;
          state.voteNoLit = true;
          break;
        case 'ABSTAIN':
          state.voteAbstainEnabled = true;
          state.voteAbstainLit = true;
          break;
        //default:
          // do nothing
      }
    },

    cancelVote(state: ButtonsState): void {
      state.myVote = undefined;

      state.voteYesEnabled = false;
      state.voteNoEnabled = false;
      state.voteAbstainEnabled = false;
      state.motionFirstEnabled = false;
      state.motionSecondEnabled = false;

      state.voteYesLit = false;
      state.voteNoLit = false;
      state.voteAbstainLit = false;
      // doesn't clear motionFirstLight and motionSecondLight
    },


    micOff(state: ButtonsState): void {
      state.micColor = 'off';
      //Added the following to test which mode the system is in
      if (state.micRtsMode === 'DISCUSS_ON') {
        state.micText = 'MIC IS OFF';
      } else if (state.micRtsMode === 'DISCUSS_OFF') {
        state.micText = 'RTS MIC';
      } else if (state.micRtsMode === 'RTS_LIST_ONLY') {
        state.micText = 'RTS';
      } else {
        throw new Error('unhandled micOff state: ' + JSON.stringify(state));
      }
    },

    micOn(state: ButtonsState): void {
      state.micColor = 'on';
      //Added the following to test which mode the system is in
      if (state.micRtsMode === 'RTS_LIST_ONLY') {
        state.micText = 'RECOG';
      } else {
        state.micText = 'MIC IS ON';
      }
    },

    micBlink(state: ButtonsState): void {
      state.micColor = 'strobe';
      state.micText = 'IN QUEUE';
    },

    micModeOn(state: ButtonsState): void {
      state.micRtsMode = 'DISCUSS_ON';
      state.micColor = 'off';
      state.micText = 'MIC OFF';
      state.micStatus = 'Discussion: You Control Mic';
    },

    micModeOff(state: ButtonsState): void {
      state.micRtsMode = 'DISCUSS_OFF';
      state.micColor = 'off';
      state.micText = 'RTS MIC';
      state.micStatus = 'RTS: Chair Control Mic';
    },

    micModeListOnly(state: ButtonsState): void {
      state.micRtsMode = 'RTS_LIST_ONLY';
      state.micText = 'RTS';
      state.micColor = 'off';
      state.micStatus = 'RTS: You Control Mic';
    }

  }
};

export default buttonsStore;
