
















import Vue from 'vue';
import { mapState, mapActions } from 'vuex';

export default Vue.extend({

  computed: {

    ...mapState('message', [
      'socketFail'
    ])

  },

  methods: {

    ...mapActions('message', [
      'connectionRetry'
    ])

  }

});

