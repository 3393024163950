
export default function cleanMessage(msg: string | undefined): string | undefined {
  let m = msg;
  if (m) {
    if (m[0] === '*') {
      m = m.substring(1); // trim '*' from start
    }
    if (m[m.length - 1] === '!') {
      m = m.substring(0, m.length - 1); // trim '!' from end
    }
  }
  return m;
}
