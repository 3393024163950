import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { StateRoot } from '../types/store';
import buttons from './buttons';
import meeting from './meeting';
import panel from './panel';
import voteTotals from './voteTotals';
import message from './message';

Vue.use(Vuex);

const store: StoreOptions<StateRoot> = {
  state: {} as StateRoot,
  mutations: {
  },
  actions: {},
  modules: {
    buttons,
    meeting,
    panel,
    voteTotals,
    message
  }
};

export default new Vuex.Store<StateRoot>(store);
