

















import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({

  computed: {

    ...mapState('meeting', [
      'agendaCurrentNumber',
      'agendaCurrentText',
      'motionFirstBy',
      'panelId',
      'motionSecondBy'
    ])

  }

});
